import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { App } from './components/App';
import { SgwtConnectHTMLElement } from './types/sgwt-widgets';
import { SGWTConnectCore, SGWTConnectError } from '@sgwt/connect-core';
import { sgwtConnect } from '@/widgets/sgwtConnect';
import { IntlProvider } from '@/context/IntlContext';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { TradeBlotter } from './components/routes/TradeBlotter/TradeBlotter';
import { StockReconciliation } from './components/routes/StockReconciliation/StockReconciliation';
import { Home } from './components/routes/Home/Home';
import { store } from './store/store';
import { setupAgGridLicence } from '@sgme/aggrid';

setupAgGridLicence();
setupSgwtConnectWidget(sgwtConnect);

if (sgwtConnect.isAuthorized()) {
  renderApp();
} else {
  const authorizationError = sgwtConnect.getAuthorizationError();
  if (authorizationError !== null) {
    renderError(authorizationError);
  } else {
    sgwtConnect.requestAuthorization();
  }
}

function renderApp() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <App />,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/trade-blotter',
          element: <TradeBlotter />,
        },
        {
          path: '/trade-blotter/:userId',
          element: <TradeBlotter />,
        },
        {
          path: '/stock-reconciliation',
          element: <StockReconciliation />,
        },
      ],
    },
  ]);

  const rootElement = document.getElementById('root')!;
  const root = createRoot(rootElement);

  root.render(
    <StrictMode>
      <IntlProvider>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </IntlProvider>
    </StrictMode>,
  );
}

function renderError(authorizationError: SGWTConnectError) {
  document.body.innerHTML = `
      <div class='alert alert-danger' role='alert'>
        Authorization error: ${authorizationError}.
      </div>`;
}

function setupSgwtConnectWidget(sgwtConnect: SGWTConnectCore) {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
  if (widget) {
    // When the code is executed, the swgtConnectWidget may not have been initialized. So, we need to check that, otherwise calling
    // `swgtConnectWidget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the swgtConnectWidget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}
