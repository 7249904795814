import { getConfig } from '@/config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { commonPrepareHeaders } from './apiUtils';

export const tradeReportingApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.apiUrl ?? ''}/stock-reconciliation/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  endpoints: builder => ({
    getJobDetails: builder.query<
      GetJobDetailsRequest,
      { jobName: string; stockReconciliationRequest: StockReconciliationRequest }
    >({
      query: ({ jobName, stockReconciliationRequest }) => ({
        // url: `${  }/_get`,
        url: `jobs/_get`,
        method: 'POST',
        body: stockReconciliationRequest,
        responseHandler: response => response.json(),
      }),
    }),
  }),
});

export const { useLazyGetJobDetailsQuery } = tradeReportingApi;
