import { AccountCenter } from '@/components/sgwt/AccountCenter';
import { useCallback, useState } from 'react';
import { getTheme, setTheme, Theme } from '@/utils/theme';
import { Switcher } from '@/components/bootstrap/Switcher';
import { ApplicationRoutes } from '@/helpers/app-routes';
import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export function Header(): JSX.Element {
  return (
    <Navbar className="navbar border-bottom">
      <div className="navbar-title">
        <div className="navbar-title-link">
          <NavbarBrand className="navbar-logo" href="/">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="0" width="100%" height="50%" fill="var(--bs-socgen)"></rect>
              <rect x="0" y="50%" width="100%" height="50%" fill="#1D1D1B"></rect>
              <rect x="17%" y="46.5%" width="65%" height="7%" fill="white"></rect>
            </svg>
            <div className="navbar-title-divider" />
            <div className="navbar-service-name">
              React
              <br />
              Starter
            </div>
          </NavbarBrand>
        </div>
      </div>

      <div className="navbar-content">
        <Nav>
          {Object.entries(ApplicationRoutes).map(route => (
            <NavItem key={route[1].navigateTo.pathname}>
              <NavLink to={route[1].navigateTo} className="navbar-link">
                <FormattedMessage id={route[1].title.id} values={route[1].title.values} />
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>

      <div className="navbar-toolbar">
        <ThemeSwitcher />
        <AccountCenter />
      </div>
    </Navbar>
  );
}

function ThemeSwitcher(): JSX.Element {
  const [currentTheme, setCurrentTheme] = useState(getTheme);
  const changeTheme = useCallback((checked: boolean) => {
    const theme: Theme = checked ? 'DARK' : 'LIGHT';
    setTheme(theme);
    setCurrentTheme(theme);
  }, []);
  return (
    <Switcher id="dark" label="Dark" checked={currentTheme === 'DARK'} onChange={changeTheme} />
  );
}
