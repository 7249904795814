import { combineReducers, configureStore, PreloadedState, SerializedError } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import userReducer from './userSlice';
import { tradeReportingApi } from './tradeReportingApi';

const appReducer = combineReducers({
  user: userReducer,
  [tradeReportingApi.reducerPath]: tradeReportingApi.reducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setupStore = (preloadedState?: PreloadedState<AppState>) =>
  configureStore({
    reducer: appReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['user/setSgConnect'],
          ignoredPaths: ['user.globalContext.setClientScope', 'user.globalContext.sgConnect'],
        },
      }).concat(tradeReportingApi.middleware),
  });

export function getErrorMessage(error: FetchBaseQueryError | SerializedError): string {
  let errMsg: string;
  if ('status' in error) {
    errMsg =
      typeof error.data === 'string'
        ? error.data
        : JSON.stringify(error.data) ?? ('error' in error ? error.error : 'Unexpected error');
  } else {
    errMsg = error.message ?? 'Unexpected error';
  }
  return errMsg;
}

export type AppState = ReturnType<typeof appReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const store = setupStore();
