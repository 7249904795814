import { useParams } from "react-router-dom";

export const TradeBlotter : React.FC = () => {
    const { userId } = useParams();

    return (
        <div>
            Hello from Trade Blotter {userId}
        </div>
    );
}