import { Header } from '@/components/layout/Header';
import { Footer } from '@/components/layout/Footer';
import { Outlet } from 'react-router-dom';
import './App.css';

export function App() {
  return (
      <div className="app">
        <Header />
        <Outlet />
        <Footer />
      </div>
  );
}
