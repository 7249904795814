import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import '@ag-grid-community/styles/ag-grid.css';
import '@sg-bootstrap/ag-grid-theme/dist/28-standard.min.css';
import { StockReconciliationFilter } from './StockReconciliationFilter';
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModuleRegistry,
  SideBarDef,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';

ModuleRegistry.registerModules([ClientSideRowModelModule, ColumnsToolPanelModule, MenuModule]);

const gridOptions: GridOptions = {
  headerHeight: 56,
  rowHeight: 48,
};

const AgGridContainer = styled.div.attrs({
  className: 'ag-theme-sg-bootstrap bg-lvl1',
})`
  height: 80vh;
`;

export const StockReconciliation: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'JobName',
      headerName: 'JobName',
    },
    {
      field: 'AssetClass',
      headerName: 'AssetClass',
    },
    {
      field: 'ResultStatus',
      headerName: 'ResultStatus',
    },
    {
      field: 'ElectronicId ',
      headerName: 'ElectronicId ',
    },
    {
      field: 'XOneReference',
      headerName: 'XOneReference',
    },
    {
      field: 'TradeDate',
      headerName: 'TradeDate',
    },
    {
      field: 'ModificationTime',
      headerName: 'ModificationTime',
    },
    {
      field: 'ReconciliationDate',
      headerName: 'ReconciliationDate',
    },
  ]);

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressRowGroups: true,
          },
          minWidth: 225,
          maxWidth: 225,
        },
      ],
    }),
    [],
  );

  const getRowData = () => {
    return [];
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      resizable: true,
      filter: false,
      flex: 1,
      menuTabs: ['columnsMenuTab', 'filterMenuTab'],
    }),
    [],
  );

  const getColumnDefs = () =>
    columnDefs.map((column: ColDef) => ({
      ...column,
    }));

  const onGridReady = (params: GridReadyEvent) => {
    params.api.showLoadingOverlay();
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const onColumnVisible = () => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  };

  return (
    <>
      <div className="main-content bg-lvl2">
        <div className="main-header d-flex flex-column justify-content-end pt-4 border-bottom bg-lvl1">
          <div className="container mb-1">
            <div className="row d-flex">
              <div className="col-lg-10 col-10">
                <h2>Electronic Reconciliation</h2>
              </div>
            </div>
          </div>
          <div className="container">
            <StockReconciliationFilter />
          </div>
          {getRowData().length >= 0 && (
            <AgGridContainer>
              <AgGridReact
                rowData={getRowData()}
                columnDefs={getColumnDefs()}
                gridOptions={gridOptions}
                sideBar={sideBar}
                onColumnVisible={onColumnVisible}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
              />
            </AgGridContainer>
          )}
        </div>
      </div>
    </>
  );
};
