import { getUserConnection } from "@/widgets/widgets";
import { FormattedMessage } from "react-intl";

export const Home: React.FC = () => {
    return (
        <main className="p-4">
            <i className="icon me-3">person</i>
            <FormattedMessage id={'App.Welcome'} values={{ user: getUserConnection()?.mail }} />
        </main>
    );
}