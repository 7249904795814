export const ApplicationRoutes: Record<string, NavigationRouteDetail> = {
    home:{
        title: {
            id: 'menu.home'
        },
        body: {
            id: 'home.home-summary'
        },
        navigateTo: {
            pathname: '/',
            state: { shouldResetScroll: true }
        },
        icon: 'groups'
    },
    tradeBlotter:{
        title: {
            id: 'menu.trade-blotter'
        },
        body: {
            id: 'home.trade-blotter-summary'
        },
        navigateTo: {
            pathname: '/trade-blotter/',
            state: { shouldResetScroll: true }
        },
        icon: 'groups'
    },
    stockReconciliation:{
        title: {
            id: 'menu.stock-reconciliation'
        },
        body: {
            id: 'home.stock-reconciliation-summary'
        },
        navigateTo: {
            pathname: '/stock-reconciliation/',
            state: { shouldResetScroll: true }
        },
        icon: 'groups'
    }
}