import React, { useState } from 'react';
import buttonStyled from 'styled-components';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import styled from 'styled-components';
import { useLazyGetJobDetailsQuery } from '@/store/tradeReportingApi';

const SearchButton = buttonStyled.button.attrs({
  className: 'form-control form-control-xl ml-2 col-md-2',
})`  
font-weight: bold;
font-size: 22px !important;
padding-top: 8px !important; 
`;
const ScrollableDropDownMenu = styled(DropdownMenu)`
  max-height: 30vh;
  overflow-y: scroll;
`;

export const StockReconciliationFilter: React.FC = () => {
  const [dateTimeFromInput, setDateTimeFromInput] = useState<string>(
    new Date().toISOString().slice(0, 16),
  );
  const [dateTimeToInput, setDateTimeToInput] = useState<string>(
    new Date().toISOString().slice(0, 16),
  );

  const [assetList, setAssetList] = React.useState<string[]>([
    'IRD',
    'FIXEDINCOME',
    'FX',
    'CDV',
    'SECLOAN',
    'CASHLOAN',
    'STRUCTUREDPRODUCT',
    'EQUITYLISTED',
    'FXO',
    'COMMODITY',
  ]);
  const [currentActionIndex, setCurrentActionIndex] = React.useState<number>(0);
  const [defaultAsset, setDefaultAsset] = React.useState<string>('IRD');

  const [triggerGetJobDetails, { data: reconciliationList, isSuccess, isLoading }] =
    useLazyGetJobDetailsQuery();

  const onLoadHandler = () => {
    const stockReconciliationRequestData: StockReconciliationRequest = {
      assetClass: defaultAsset,
      startDate: dateTimeFromInput,
      endDate: dateTimeToInput,
    };

    triggerGetJobDetails({
      jobName: 'jobName',
      stockReconciliationRequest: stockReconciliationRequestData,
    });
  };

  const onClickAsset = (index: number) => {
    setCurrentActionIndex(index);
    setDefaultAsset(assetList[index]);
  };

  return (
    <div className="form-group m-1">
      <div className="input-group input-group-xl">
        <div className="btn-group">
          <UncontrolledDropdown className="btn-group m-1">
            <DropdownToggle
              className="shadow-none dropdown-toggle dropdown-toggle-split"
              type="button"
              data-toggle="dropdown"
              color="primary"
            >
              <span className="sr-only">{defaultAsset}</span>
            </DropdownToggle>
            <ScrollableDropDownMenu>
              {assetList &&
                assetList.map((assetClass, index) => (
                  <DropdownItem
                    key={assetClass}
                    active={index === currentActionIndex}
                    onClick={() => onClickAsset(index)}
                  >
                    {assetClass}
                  </DropdownItem>
                ))}
            </ScrollableDropDownMenu>
          </UncontrolledDropdown>
        </div>
        <input
          value={dateTimeFromInput}
          type="datetime-local"
          className="form-control form-control-m ml-3 col-md-3"
          max={new Date().toISOString().slice(0, 16)}
          min="1970-00-00T00:00"
        />
        <input
          value={dateTimeToInput}
          type="datetime-local"
          className="form-control form-control-xl ml-3 col-md-3"
          max={new Date().toISOString().slice(0, 16)}
          min="1970-00-00T00:00"
        />
        <SearchButton type="button" onClick={onLoadHandler}>
          <i className="icon icon-md">search</i>
        </SearchButton>
      </div>
    </div>
  );
};
